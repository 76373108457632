.parking_notice {

    padding: 1rem;
    background-color: rgb(253, 253, 255);
    margin: 2rem auto;
    width: 50rem;
    max-width: 85%;
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);

}
